export default function PlayerVideo() {
    let videos = document.querySelectorAll(".video") as NodeListOf<Element>;
    if (videos.length > 0) {
        for (const video of videos as any) {
            video.addEventListener("click", clickPlayVideo);
        }
    }

    function clickPlayVideo(this: any) {
        playVideo(this);
    }

    function playVideo(video: any) {
        const id = video.getAttribute("data-id") as string | null;
        video.classList.add("loaded");

        const src =
            "https://www.youtube.com/embed/" +
            id +
            "?rel=0&amp;showinfo=0&amp;enablejsapi=1&amp;widgetid=1&autoplay=1&mute=1";
        const iframe =
            "<iframe class='player' frameborder='0' allowfullscreen='1' allow='autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture' title='YouTube video player' width='100%' height='450' src='" +
            src +
            "' scrolling='no frameborder='0' allowfullscreen></iframe>";

        video.innerHTML = iframe;

        return false;
    }

    let video = document.querySelector(
        ".page-node-type-home .block--video .video"
    ) as HTMLElement;
    let inView = 0;

    if (video) {
        window.onscroll = () => {
            let observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.intersectionRatio === 1 && inView == 0) {
                            playVideo(video);
                            inView++;
                        }
                    });
                },
                { threshold: 0.2 }
            );
            observer.observe(video);
        };
    }
}
