export default function StoreSwitch() {
    const switchContainer = document.querySelector('.store-locator__switch') as HTMLElement;
    const switchList = document.querySelector('.store-locator__switch--list') as HTMLElement;
    const switchMap = document.querySelector('.store-locator__switch--map') as HTMLElement;
    const list = document.querySelector('.store-locator__list') as HTMLElement;
    const map = document.querySelector('.store-locator__map') as HTMLElement;

    if (switchContainer) {
        if (window.innerWidth <= 900) {
            map.classList.add('d-none');
        }

        switchList.addEventListener('click', () => {
            switchMap.classList.remove('active');
            list.classList.remove('d-none');
            map.classList.add('d-none');
        });

        switchMap.addEventListener('click', () => {
            switchMap.classList.add('active');
            list.classList.add('d-none');
            map.classList.remove('d-none');
        });
    }
}
