export default function ShowResponsiveMenu() {
  /**
   * Vérification de l'éxistence du cta et du menu principal
   * ****************************************
   */
  const openMobileMenuButton = document.querySelector(
    ".open-mobile-menu"
  ) as HTMLElement;

  const closeMobileMenuButton = document.querySelector(
    ".close-mobile-menu"
  ) as HTMLElement;

  const MobileMenuButtons = [openMobileMenuButton, closeMobileMenuButton];

  const mobileMenu = document.querySelector(".header__menu-main") as HTMLElement;

  /**
   * Switch de class
   * ****************************************
   */
  MobileMenuButtons.forEach((button) => {
    button.onclick = () => {
      mobileMenu.classList.toggle( 'active');
      document.body.classList.toggle('overflow-hidden')
    };
  });
}
