export default function StoreLocatorMenuMobile() {
    const formMobileFind = document.querySelector('.form-mobile>.input') as HTMLElement;
    const formMobileFilter = document.querySelector('.form-mobile>.button') as HTMLElement;
    const storeLocatorForm = document.querySelector('.store-locator__form') as HTMLElement;
    const storeLocatorFormClose = storeLocatorForm.querySelector('.fa-xmark-large') as HTMLElement;
    const storeLocatorFormFindTitle = storeLocatorForm.querySelector('.find') as HTMLElement;
    const storeLocatorFormFilterTitle = storeLocatorForm.querySelector('.filter') as HTMLElement;
    const storeLocatorFormFindOption = storeLocatorForm.querySelector('.form__location') as HTMLElement;
    const storeLocatorFormFilterOption = storeLocatorForm.querySelector('.jobs') as HTMLElement;

    if (window.innerWidth <= 900) {
        formMobileFind.addEventListener('click', () => {
            storeLocatorForm.classList.add('active');
            document.body.classList.add('overflow-hidden');
            storeLocatorFormFilterTitle.classList.add('d-none');
            storeLocatorFormFilterOption.classList.add('d-none');
        });

        formMobileFilter.addEventListener('click', () => {
            storeLocatorForm.classList.add('active');
            document.body.classList.add('overflow-hidden');
            storeLocatorFormFindTitle.classList.add('d-none');
            storeLocatorFormFindOption.classList.add('d-none');
        });

        storeLocatorFormClose.addEventListener('click', () => {
            storeLocatorForm.classList.remove('active');
            setTimeout(() => {
                document.body.classList.remove('overflow-hidden');
                storeLocatorFormFilterTitle.classList.remove('d-none');
                storeLocatorFormFindTitle.classList.remove('d-none');
                storeLocatorFormFilterOption.classList.remove('d-none');
                storeLocatorFormFindOption.classList.remove('d-none');
            }, 300)
        });
    }
}
