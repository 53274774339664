export default function StoreLocatorFilters() {
    const jobsListLabels = document.querySelectorAll('.jobs__list label') as NodeListOf<HTMLElement>;
    const jobsTitle = document.querySelector('.jobs span') as HTMLElement;

    if (window.innerWidth > 900) {
        const jobsMenu = document.querySelector('.jobs__list') as HTMLElement;

        jobsTitle.addEventListener('click', () => {
            jobsMenu.classList.toggle('active');
        });

        document.addEventListener('click', function (event: MouseEvent) {
            const elem = event.target as HTMLElement;
            if (!elem.closest('.jobs__list li') && event.target !== jobsTitle) {
                jobsMenu.classList.remove('active');
            }
        });
    }

    jobsListLabels.forEach(el => {
        el.addEventListener('click', () => {
            jobsListLabels.forEach(job => {
                job.classList.remove('active');
            });
            if (window.innerWidth > 900) {
                jobsTitle.innerText = el.innerText;
            }
            el.classList.add('active');
        });
    });
}
